import React, { useState } from "react";
import "./header.css"
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import Link from "react-scroll/modules/components/Link";

export default function Header (){
    const mobile = window.innerWidth <=768 ? true : false;
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div className="Header">
             <img className="logo" src={Logo}/>
             {menuOpen === false && mobile === true? (
                 <div 
                 style={{
                     backgroundColor: 'var(--appColor)',
                     padding: '0.5rem',
                     borderRadius: '5px'
                 }}
                 onClick={()=> setMenuOpen(true)}
                 >
                 <img 
                 src={Bars} 
                 style={{width: '1.5rem', height: '1.5rem'}}/>
                 </div>):
                  <ul className="header-menu">
                 <li ><Link
                 onClick={() => setMenuOpen(false)}
                 to='home'
                 span={true}
                 smooth={true}
                 >Home</Link></li>
                 <li onClick={() => setMenuOpen(false)}><Link
                 onClick={() => setMenuOpen(false)}
                 to='programs'
                 span={true}
                 smooth={true}
                 >Programs</Link></li>
                 <li onClick={() => setMenuOpen(false)}><Link
                 onClick={() => setMenuOpen(false)}
                 to='Why-us'
                 span={true}
                 smooth={true}
                 >Why us</Link></li>
                 <li onClick={() => setMenuOpen(false)}><Link
                 onClick={() => setMenuOpen(false)}
                 to='plans'
                 span={true}
                 smooth={true}
                 >Plans</Link></li>
                 <li onClick={() => setMenuOpen(false)}><Link
                 onClick={() => setMenuOpen(false)}
                 to='testimonials'
                 span={true}
                 smooth={true}
                 >Testimonals</Link></li>
             </ul>}
            
        </div>
    )

}