import React from "react";
import "./plans.css"
import { plansData } from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";


export default function Plans () {
    return (
        <div className="plans-container" id="plans">
        <div className="blur blur-p1"></div>
        <div className="blur blur-p2"></div>
          <div className="programs-header">
              <span className="stroke-text">READY TO START</span>
              <span> YOUR JOURNEY</span>
              <span className="stroke-text">NOW WITH US</span>
          </div>
          <div className="plans">
          {plansData.map((plan, i)=> (
                  <div className="plan" key={i}>
                  {plan.icon}
                  <span>{plan.name}</span>
                  <span>$ {plan.price}</span>
                  <div className="features">
                      {plan.features.map((features, i) => (
                          <div className="feature">
                          <img src={whiteTick}/>
                          <span key={i}>{features}</span>
                       </div>
                       
                      ))}
                  </div>
                  <div><span>See more benefits -></span></div>
                  <button className="btn">Join Now</button>
                  </div>
              ))}
          </div>
        </div>
    )}