import React from "react";
import "./hero.css"
import Header from "../header/header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from 'number-counter'

export default function Hero (){
    const transition ={type: "spring", duration: 3}
    const mobile = window.innerWidth <=768 ? true : false;

    return (
        <div className="hero" id="home">
        <div className="blur blur-h"></div>
        <div className="left-h">
        <Header />
        <div className="the-best-ad">
            <motion.div
            initial={{left: mobile? '178': '238px'}}
            whileInView={{left: '8px'}}
            transition={{...transition, type: 'tween'}}
            ></motion.div>
            <span>The Best Fitness Club In The Town</span>
        </div>
        <div className="hero-text">
        <div>
        <span className="stroke-text">Shape </span>
        <span>Your</span>
        </div>
        <div>
        <span>Ideal Body</span>
        </div>
        <div>
            <span>IN HERE WE WILL HELP YOU TO SHAPE AND BUILD YOUR IDEAL BODY AND LIVE UP YOUR LIFE TO THE FULLEST</span>
        </div>
        </div>
{/*figures*/}
        <div className="figures">
            <div>
            <span><NumberCounter end={140} start={100} delay='4' prefix='+'/></span>
            <span>Expert coaches</span>
            </div>
            <div>
            <span><NumberCounter end={978} start={900} delay='4' prefix='+'/></span>
            <span>Members Joined</span>
            </div>
            <div>
            <span><NumberCounter end={50} start={20} delay='4' prefix='+'/> </span>
            <span>Fitness Programmes</span>
            </div>
        </div>
        {/* hero buttons */}
        <div className="hero-buttons">
            <button className="btn">Get Started</button>
            <button className="btn">Learn More</button>
        </div>
         </div>
        <div className="right-h">
            <button className="btn">Join Now</button>
            <motion.div 
            initial={{right: "-1rem"}}
            whileInView={{right: "5rem"}}
            transition={transition}

            className="heart-rate">
                <img src={Heart}/>
                <span>Heart rate </span><span>116 bpm</span>
            </motion.div>
            {/* hero-images */}
            <img src={hero_image} className="hero-images"/>
            <motion.img 
            initial={{right: "11rem"}}
            whileInView={{right: "20rem"}}
            transition={transition}
            src={hero_image_back} className="hero-images-back"/>
            {/* calories */}
            <motion.div
            initial={{right: "37rem"}}
            whileInView={{right: "28rem"}}
            transition={transition}
             className="calories">
                <img src={Calories}/>
                <div>
                    <span>Calories Burned</span>
                    <span>220 kcal</span> 
                </div>
               
            </motion.div>
        </div>
        </div>
    )
}