import React from "react";
import "./programs.css";
import {programsData} from "../../data/programsData";
import RightArrow from "../../assets/rightArrow.png";

export default function Programs(){
    return(
        <div className="programs" id="programs">
          <div className="programs-header">
              <span className="stroke-text">Explore Our</span>
              <span>Programs</span>
              <span className="stroke-text">to shape you</span>
          </div> 
          <div className="program-categories">
              {programsData.map((data) => (
                  <div className="categories">
                    {data.image}
                    <span>{data.heading}</span>
                    <span>{data.details}</span>
                    <div className="join-now">
                        <span>Join now</span>
                        <img src={RightArrow}/>
                    </div>
                  </div>
              ))}
          </div>
        </div>
    )
}