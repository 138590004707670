import React, { useRef } from "react";
import "./joinNow.css";
import emailjs from "@emailjs/browser";

export default function JoinNow(){
    const form = useRef();
      
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs.sendForm('service_94ilmzt', 'template_jyj3jn8', form.current, 'ywjBTax1W2U2BbxE1')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        };
    return(
        <div className="join-now">
            <div className="left-j">
            <hr/>
                <div>
                    <span className="stroke-text">Ready To</span>
                    <span> Level Up</span>
                </div>
                <div>
                    <span>Your Body</span>
                    <span className="stroke-text"> With Us</span>
                </div>
            </div>
            <div className="right-j">
                <form
                ref={form} 
                onSubmit={sendEmail}
                className="email-container">
                    <input 
                    name="user_email"
                    type="email"
                    placeholder="enter user email"
                    ></input>
                    <button className="btn btn-j">Join Now</button>
                </form>
            </div>
        </div>
    )
}