import './App.css';
import Hero from "./component/hero section/hero";
import Programs from "./component/programs/programs"
import Reasons from "./component/Reasons/reasons";
import Plans from "./component/plans/plans"
import Testimonials from './component/testimonials/testimonials';
import JoinNow from './component/join-now/joinNow';
import Footer from './component/footer/footer';

function App() {
  return (
    <div className="App">
          <Hero />
          <Programs />
          <Reasons />
          <Plans />
          <Testimonials />
          <JoinNow />
          <Footer />
    </div>
  );
}

export default App;
